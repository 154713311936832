export default {
  data() {
    return {
      baseColumns: [],
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      sortFields: [], // 用户自定义的排序设置
    }
  },
  methods: {
    initConfig() {
      this.tableColumns = this.baseColumns.filter(v => v.isShow)
      this.searchFields = this.baseColumns.filter(v => v.isSearchShow)
      this.sortFields = this.tableColumns
        .filter(v => v.sortable)
        .reduce((prev, item) => {
          // if(item.sortList) prev = prev.concat(item.sortList)
          prev.push({
            title: `${item.title}升序`,
            field: `${item.field}-asc`,
          })
          prev.push({
            title: `${item.title}降序`,
            field: `${item.field}-desc`,
          })
          return prev
        }, [])
    },
  }
}