const baseColumn = vm => {
  return [
    {
      keyId: 1,
      title: '创建时间',
      field: 'created',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}
// 操作按钮配置项
const operateList = vm => {
  return []
}

// 搜索字段配置项
const searchFields = vm => {
  return [
    {
      keyId: 11,
      title: '日期',
      field: 'channelName',
      width: 160,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      renderType: 'status',
      copy: true, //是否拥有复制功能
      columnType: null, // 筛选自定义
      filterable: true // 是否可筛选
    },
    {
      keyId: 12,
      title: '车牌号码',
      field: 'licensePlate',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: true,
      selectOptions: [{
        value:'lpr',
        label:'车牌识别'
      }],
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
  ]
}

// 表格列配置
const tableFields = vm => {
  return [

    {
      "keyId": 2,
      "title": "告警时间",
      "field": "dateTime",
      "width": 160,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 2,
      "title": "保存数据库时间",
      "field": "created",
      "width": 160,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 3,
      "title": "车牌号码",
      "field": "licensePlate",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 4,
      "title": "方向",
      "field": "direction",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 5,
      "title": "车牌颜色",
      "field": "plateColor",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 8,
      "title": "通道名称",
      "field": "channelName",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 9,
      "title": "车头照片",
      "field": "carHeadImg",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": "image",
      "copy": true
    },
    {
      "keyId": 10,
      "title": "车斗照片",
      "field": "carBodyImg",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": 'image',
      "copy": true
    },
    {
      "keyId": 11,
      "title": "车头照片时间",
      "field": "headImgTime",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 12,
      "title": "车斗照片时间",
      "field": "bodyImgTime",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },

    ]
}

export default {
  operateList,
  searchFields,
  tableFields,
  baseColumn
}
