import request from '@/utils/http'

// 项目列表
export function queryList(params) {
  return request({
    url: '/eventNotify/pc/list',
    method: 'get',
    params
  })
}





